<template>
  <section class="w-full flex flex-col justify-center items-center md:px-5 px-20 py-10">
    <h1 class="text-2xl font-medium capitalize textColorDark">
      Subscribe to our newsletter
    </h1>
    <h2 class="text-lg font-varelaRound capitalize textColorDarkAccent">
      And always get the latest blog posts
    </h2>

    <!-- Email address input field -->
    <div class="md:w-full w-1/4 py-10 my-auto relative">
      <input
        type="text"
        placeholder="Email Address"
        class="inputStyle w-full"
      />
      <button class="my-auto absolute right-2">
        <Icon icon="fluent:send-16-filled" color="var(--appColor-dark)" />
      </button>
    </div>
  </section>
</template>

<script>
import { Icon } from "@iconify/vue";

export default {
  components: {
    Icon,
  },
};
</script>

<style></style>
