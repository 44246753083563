<template>
  <div class="w-full md:px-5 px-20 py-32">
    <div
      class="w-full flex flex-col"
      v-for="item in article"
      :key="item.category"
    >
      <!-- Category -->
      <div class="py-4 flex flex-row items-center">
        <Icon
          :icon="item.category_icon"
          color="var(--appColor-main)"
          height="24"
          v-if="item.category != `Fashion & Lifestyle`"
        />
        <img
          :src="require(`@/assets/icons/fashion.svg`)"
          alt="blog image"
          class=""
          v-else
        />
        <h3 class="ml-2 text-xl font-varelaRound textColorMain">
          {{ item.category }}
        </h3>
      </div>

      <!-- Post date & author -->
      <div class="py-1">
        <p class="textColorMain font-varelaRound">
          {{ item.post_date }} - By {{ item.author }}
        </p>
      </div>

      <!-- Blog image -->
      <div class="w-full my-4 md:h-52 h-72">
        <img
          class="maintainRatio"
          :src="require(`@/assets/images/${item.image}`)"
          alt="blog image"
        />
      </div>

      <div class="w-full flex md:flex-col flex-row py-2">
        <!-- Main Blog Content Div  -->
        <div class="md:w-full w-3/5">
          <!-- Blog title -->
          <div class="py-2">
            <h2 class="font-varelaRound text-2xl textColorDark">
              {{ item.blogTitle }}
            </h2>
          </div>

          <!-- Blog likes, shares & comments -->
          <div class="py-5 flex flex-row">
            <div class="flex flex-row items-center">
              <Icon
                icon="wpf:like"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              <p class="ml-2 textColorDarkAccent font-quattrocento">
                {{ item.likes }}
              </p>
            </div>

            <div class="ml-4 flex flex-row items-center">
              <Icon
                icon="ci:share"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              <p class="ml-2 textColorDarkAccent font-quattrocento">
                {{ item.shares }}
              </p>
            </div>

            <div class="ml-4 flex flex-row items-center">
              <Icon
                icon="dashicons:admin-comments"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              <p class="ml-2 textColorDarkAccent font-quattrocento">
                {{ item.comments }}
              </p>
            </div>
          </div>

          <div class="w-full">
            <p
              class="py-1 text-lg font-quattrocento textColorDark"
              v-for="paragraph in item.paragraphs"
              :key="paragraph.index"
            >
              {{ paragraph.paragraph }}
            </p>
          </div>
        </div>

        <div class="md:w-full w-2/5 md:mt-5 md-min:px-10">
          <!-- Author div -->
          <AuthorDiv :authorDetails="authorDetails" />

          <!-- Blog actions -->
          <div class="py-10 flex flex-row">
            <!-- Like button -->
            <button
              class="font-medium textColorDark flex flex-row items-center"
            >
              <Icon
                class="mr-2"
                icon="wpf:like"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              Like
            </button>

            <!-- Share btn -->
            <button
              class="ml-4 font-medium textColorDark flex flex-row items-center"
            >
              <Icon
                class="mr-2"
                icon="ci:share"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              Share
            </button>
          </div>
        </div>
      </div>

      <!-- Previous/next -->
      <div class="w-full py-10">
        <h3 class="py-5 text-lg textColorDark">More blogs</h3>
        <NextArticleSelectSection />
      </div>

      <!-- Comments section -->
      <div class="w-full mt-5">
        <CommentsSection :comments="comments" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import AuthorDiv from "./Sections/AuthorDiv.vue";
import CommentsSection from "./Sections/CommentsSection.vue";
import NextArticleSelectSection from "./Sections/NextArticleSelectSection.vue";

export default {
  name: "SingleBlogPost",

  components: {
    Icon,
    AuthorDiv,
    CommentsSection,
    NextArticleSelectSection,
  },

// Sample data used for demo
  data() {
    return {
      article: [
        {
          category_icon: "ant-design:camera-filled",
          category: "Photography",
          post_date: "16/09/2021",
          author: "Peter p.",
          image: "patrick-dozkVhDyvhQ-unsplash.jpg",
          blogTitle: "My Journey as a photographer",
          paragraphs: [
            {
              index: 1,
              paragraph:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer iaculis urna in sollicitudin posuere. Curabitur id felis posuere, cursus ipsum eget, auctor magna. Ut mollis, turpis quis euismod tincidunt, mi neque vestibulum tortor, quis sodales sapien leo sit amet massa. Ut vel metus vitae nisl commodo vehicula. Nulla fringilla luctus ultrices. Integer vehicula semper lorem in lacinia. Aenean placerat augue ut lectus varius auctor. Suspendisse mollis lectus lacus, nec interdum leo sodales nec. Nunc id ultrices arcu, sit amet ultrices ante. Cras vehicula dignissim imperdiet. Aliquam erat volutpat. Nunc a enim viverra, lobortis justo sit amet, tempor sapien. Donec id aliquet dui. Vestibulum non turpis vitae leo varius pulvinar.",
            },
            {
              index: 2,
              paragraph:
                "Nulla id nulla nec odio viverra auctor a et risus. Duis porta feugiat diam ut ultrices. Vivamus id nisl eget erat viverra efficitur a eu risus. Vestibulum faucibus risus id metus faucibus tempor. Mauris faucibus, massa at pharetra ullamcorper, elit ligula ultricies lorem, a convallis augue lacus nec metus. Ut gravida elit id sapien posuere, nec luctus tortor efficitur. Cras ultrices nisi quis quam ornare finibus sed at lectus.",
            },
            {
              index: 3,
              paragraph:
                "Proin consectetur justo nec nunc eleifend, at laoreet massa hendrerit. Praesent suscipit elit vitae sem aliquam luctus. Cras iaculis justo vitae metus egestas porta. Vivamus commodo turpis faucibus mattis finibus. Nullam quis congue enim, eu vehicula libero. Nam in tellus eget turpis venenatis varius eu et enim. Proin ac diam sit amet velit tristique elementum et ut tortor. Etiam id neque porttitor, vulputate eros non, feugiat dolor. Nunc et purus in sapien consequat pharetra eu ac nulla. Proin finibus quis lacus et rutrum. Sed luctus varius egestas. Donec posuere, magna id rutrum imperdiet, lorem leo tristique est, quis rutrum odio ante sit amet velit. Donec justo lectus, vestibulum non malesuada et, imperdiet non turpis. Ut suscipit condimentum massa, vel ornare ante auctor sit amet. Sed auctor turpis non facilisis pharetra.",
            },
          ],
          previewText:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer iaculis urna in sollicitudin posuere. Curabitur id felis posuere, cursus ipsum eget, auctor magna. Ut mollis, turpis quis euismod tincidunt, mi neque vestibulum torto",
          likes: 39,
          shares: 23,
          comments: 11,
        },
      ],

      authorDetails: [
        {
          authorName: "Peter p.",
          authorPic: "steve-halama-T9A31lqrXnU-unsplash.jpg",
          authorEmail: "Pete.p@example.com",
          authorTel: null,
          authorBio:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et blandit eros. Vestibulum nec leo vitae est sagittis ultrices eleifend in sem....",
          socialMedia: [
            {
              name: "Twitter",
              to: "https://twitter.com/",
              icon: "mdi:twitter",
            },
            {
              name: "Instagram",
              to: "https://www.instagram.com/",
              icon: "akar-icons:instagram-fill",
            },
          ],
        },
      ],

      comments: [
        {
          name: "John D.",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et blandit eros. Vestibulum nec leo vitae est sagittis ultrices eleifend in sem....",
          date: "13/09/2021 9:34pm",
          dislikes: 0,
          likes: 12,
          replies: [
            {
              name: "Jeff K.",
              comment:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et blandit eros. Vestibulum nec leo vitae est sagittis ultrices eleifend in sem....",
              date: "13/09/2021 9:34pm",
              dislikes: 0,
              likes: 1,
            },
          ],
        },
        {
          name: "John D.",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et blandit eros. Vestibulum nec leo vitae est sagittis ultrices eleifend in sem....",
          date: "13/09/2021 9:34pm",
          dislikes: 0,
          likes: 12,
          replies: [
            {
              name: "Jeff K.",
              comment:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et blandit eros. Vestibulum nec leo vitae est sagittis ultrices eleifend in sem....",
              date: "13/09/2021 9:34pm",
              dislikes: 0,
              likes: 1,
            },
            {
              name: "Jane D.",
              comment:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et blandit eros. Vestibulum nec leo vitae est sagittis ultrices eleifend in sem....",
              date: "13/09/2021 9:34pm",
              dislikes: 0,
              likes: 1,
            },
          ],
        },
        {
          name: "John D.",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et blandit eros. Vestibulum nec leo vitae est sagittis ultrices eleifend in sem....",
          date: "13/09/2021 9:34pm",
          dislikes: 0,
          likes: 12,
        },
      ],
    };
  },
};
</script>

<style></style>
