<template>
  <section id="about" class="w-full md:px-5 px-20 py-32">
    <h1 class="text-xl font-varelaRound capitalize textColorDarkAccent">
      Privacy Policy
    </h1>

    <!-- Privacy policy content -->
    <div class="w-full py-10">
      <p
        class="text-lg font-quattrocento textColorDark"
        v-for="item in paragraphs"
        :key="item.index"
      >
        {{ item.paragraph }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // Edit text here
      paragraphs: [
        {
          index: 1,
          paragraph:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer iaculis urna in sollicitudin posuere. Curabitur id felis posuere, cursus ipsum eget, auctor magna. Ut mollis, turpis quis euismod tincidunt, mi neque vestibulum tortor, quis sodales sapien leo sit amet massa. Ut vel metus vitae nisl commodo vehicula. Nulla fringilla luctus ultrices. Integer vehicula semper lorem in lacinia. Aenean placerat augue ut lectus varius auctor. Suspendisse mollis lectus lacus, nec interdum leo sodales nec. Nunc id ultrices arcu, sit amet ultrices ante. Cras vehicula dignissim imperdiet. Aliquam erat volutpat. Nunc a enim viverra, lobortis justo sit amet, tempor sapien. Donec id aliquet dui. Vestibulum non turpis vitae leo varius pulvinar.",
        },
      ],
    };
  },
};
</script>

<style></style>
