<template>
  <section id="landing" class="w-full md:px-5 px-20 pt-32 relative">
    <!-- Social media links -->
    <div
      id="social-float"
      class="md:hidden fixed -left-10 bottom-32 rotateLink"
    >
      <div class="flex flex-row justify-start items-start ">
        <a
          class="mx-3 textColorDark uppercase text-xs font-medium"
          v-for="item in socialMedia"
          :href="`${item.to}`"
          :key="item.name"
        >
          {{ item.name }}
        </a>
      </div>
    </div>

    <!-- Recently Added topic -->
    <div class="mb-8 md-min:px-10">
      <h1 class="text-xl font-varelaRound capitalize textColorDarkAccent">
        Recently Added
      </h1>
    </div>

    <!-- Main landing page content -->
    <div class="w-full md-min:px-10">
      <masonry-wall
        :items="articles"
        :ssr-columns="1"
        :column-width="300"
        :gap="20"
      >
        <template #default="{ item, index }">
          <div class="flex flex-col" :key="index">
            <!-- Category -->
            <div class="flex flex-row items-center">
              <Icon
                :icon="item.category_icon"
                color="var(--appColor-main)"
                height="24"
                v-if="item.category != `Fashion & Lifestyle`"
              />
              <img
                :src="require(`@/assets/icons/fashion.svg`)"
                alt="blog image"
                class=""
                v-else
              />
              <h6 class="ml-2 textColorMain font-varelaRound cursor-pointer">
                {{ item.category }}
              </h6>
            </div>

            <!-- Post date & author -->
            <div class="py-1">
              <p class="textColorMain font-varelaRound">
                {{ item.post_date }} - By {{ item.author }}
              </p>
            </div>

            <router-link to="/blog-post">
              <div class="cursor-pointer">
                <!-- Image -->
                <div class="md:w-full w-72 h-52 py-2" v-if="item.image != null">
                  <img
                    :src="require(`@/assets/images/${item.image}`)"
                    alt="blog image"
                    class="maintainRatio"
                  />
                </div>

                <!-- Blog title -->
                <div class="py-1">
                  <h5 class="textColorDark font-varelaRound blogTitleOverflow">
                    {{ item.blogTitle }}
                  </h5>
                </div>

                <!-- Blog preview -->
                <div class="py-2">
                  <p
                    class="textColorDarkAccent font-quattrocento blogPreviewOverflow"
                  >
                    {{ item.previewText }}
                  </p>
                </div>
              </div>
            </router-link>

            <!-- Blog likes, shares & comments -->
            <div class="py-2">
              <div class="flex flex-row">
                <div class="flex flex-row items-center">
                  <Icon
                    icon="wpf:like"
                    color="var(--appColor-darkAccent)"
                    height="20"
                  />
                  <p class="ml-2 text-sm textColorDarkAccent font-quattrocento">
                    {{ item.likes }}
                  </p>
                </div>

                <div class="ml-4 flex flex-row items-center">
                  <Icon
                    icon="ci:share"
                    color="var(--appColor-darkAccent)"
                    height="20"
                  />
                  <p class="ml-2 text-sm textColorDarkAccent font-quattrocento">
                    {{ item.shares }}
                  </p>
                </div>

                <div class="ml-4 flex flex-row items-center">
                  <Icon
                    icon="dashicons:admin-comments"
                    color="var(--appColor-darkAccent)"
                    height="20"
                  />
                  <p class="ml-2 text-sm textColorDarkAccent font-quattrocento">
                    {{ item.comments }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </template>
      </masonry-wall>

      <!-- See more button -->
      <div class=" w-full my-8 flex flex-row justify-end">
        <button class="py-2 textColorMain">
          See More...
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { Icon } from "@iconify/vue";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      grid: null,
      allItems: null,
      rowHeight: 0,
      rowGap: 0,
      rowSpan: 0,
      x: 0,

      socialMedia: [
        {
          name: "Instagram",
          to: "https://www.instagram.com/",
          image: "instagram_icon.svg",
        },
        {
          name: "Twitter",
          to: "https://twitter.com/",
          image: "twitter_icon.svg",
        },
        {
          name: "Facebook",
          to: "https://www.facebook.com/",
          image: "facebook_icon.svg",
        },
      ],

      articles: [
        {
          category_icon: "fluent:food-24-filled",
          category: "Food",
          post_date: "18/09/2021",
          author: "Me",
          image: "lily-banse--YHSwy6uqvk-unsplash.jpg",
          blogTitle: "My Amazing Food Experience in Lamu",
          previewText:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada, massa tempus imperdiet.",
          likes: 10,
          shares: 15,
          comments: 15,
        },
        {
          category_icon: "fluent:food-24-filled",
          category: "Food",
          post_date: "18/09/2021",
          author: "Sarah H.",
          image: null,
          blogTitle: "What about milk",
          previewText:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada, massa tempus imperdiet.Ut mollis, turpis quis euismod tincidunt, mi neque vestibulum tortor, quis sodales sapien leo sit amet massa.",
          likes: 9,
          shares: 3,
          comments: 4,
        },
        {
          category_icon: "whh:travel",
          category: "Travel",
          post_date: "17/09/2021",
          author: "Vincent J.",
          image: "cristofer-maximilian-uQDRDqpYJHI-unsplash.jpg",
          blogTitle: "Chasing Sunsets & amazing tropical vibes hsh sj js ",
          previewText:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada, massa tempus imperdiet.",
          likes: 35,
          shares: 15,
          comments: 21,
        },
        {
          category_icon: "noto:dress",
          category: "Fashion & Lifestyle",
          post_date: "17/09/2021",
          author: "Sarah H.",
          image: null,
          blogTitle: "The Met Gala Stars",
          previewText:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada, massa tempus imperdiet.",
          likes: 45,
          shares: 23,
          comments: 25,
        },
        {
          category_icon: "fluent:food-24-filled",
          category: "Food",
          post_date: "17/09/2021",
          author: "Joy G",
          image: null,
          blogTitle: "The truth about coffee",
          previewText:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada, massa tempus imperdiet.",
          likes: 27,
          shares: 15,
          comments: 7,
        },
        {
          category_icon: "ant-design:camera-filled",
          category: "Photography",
          post_date: "16/09/2021",
          author: "Peter p.",
          image: "patrick-dozkVhDyvhQ-unsplash.jpg",
          blogTitle: "My Journey as a photographer",
          previewText:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer iaculis urna in sollicitudin posuere. Curabitur id felis posuere, cursus ipsum eget, auctor magna. Ut mollis, turpis quis euismod tincidunt, mi neque vestibulum torto",
          likes: 39,
          shares: 23,
          comments: 11,
        },
      ],
    };
  },

  methods: {
    checkOffset() {
      if (
        window.scrollY >=
        document.getElementById("landing").scrollHeight -
          document.getElementById("about").scrollHeight * 1.75
      ) {
        document.getElementById("social-float").classList.remove("fixed");
        document.getElementById("social-float").classList.add("absolute");
      } else {
        document.getElementById("social-float").classList.remove("absolute");
        document.getElementById("social-float").classList.add("fixed");
      }
    },
  },

  mounted() {
    window.addEventListener("scroll", this.checkOffset);
  },

  unmounted() {
    window.removeEventListener("scroll", this.checkOffset);
  },
};
</script>

<style>
.rotateLink {
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); /* Safari */
  -moz-transform: rotate(-90deg); /* Firefox */
  -ms-transform: rotate(-90deg); /* IE */
  -o-transform: rotate(-90deg); /* Opera */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3); /* Internet Explorer */
}

.blogTitleOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
}

.blogPreviewOverflow {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-width: 300px;
  overflow: hidden;
}
</style>
