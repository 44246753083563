<template>
  <footer class="w-full largeScreenLayout">
    <div class="md:px-5 px-20 nav_footerLargeScreenSize">
      <div class="w-full py-5 textColorDark footerDivBorder">
        <!-- logo -->
        <div class="w-full flex flex-row justify-center">
          <img :src="require('@/assets/logo.png')" alt="logo" class="h-8" />
        </div>

        <!-- Links -->
        <div class="w-full pt-3 grid grid-cols-3 md:grid-cols-1 gap-5">
          <!-- Contact Details -->
          <div class="flex flex-col items-center">
            <div class="flex flex-col md:items-center">
              <a
                class="my-1 flex flex-row textColorDark uppercase font-medium"
                v-for="detail in contactDetails"
                :href="`${detail.contact}`"
                :key="detail.type"
              >
                <img
                  :src="require(`@/assets/icons/${detail.icon}`)"
                  class="my-auto"
                />
                <p class="my-auto ml-2">{{ detail.detail }}</p>
              </a>
            </div>
          </div>

          <!-- Social Media Links -->
          <div class="w-full flex flex-col items-center">
            <a
              class="my-1 textColorDark uppercase font-medium"
              v-for="item in socialMedia"
              :href="`${item.to}`"
              :key="item.name"
            >
              {{ item.name }}
            </a>
          </div>

          <!-- Internal Links -->
          <div class="w-full flex flex-col items-center">
            <div class="flex flex-col md:items-center">
              <router-link
                v-for="item in internalLinks"
                :key="item.to"
                :to="item.to"
                class="my-1 font-medium uppercase"
              >
                {{ item.title }}
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Copyright Div -->
      <div class="w-full py-5 text-center md:text-sm">
        <p class="font-rubik textColorDark">
          &copy; Copyright {{ currentYear }} MySideBlog.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      internalLinks: [
        { title: "Become a blogger", to: "/become-a-blogger" },
        { title: "Privacy Policy", to: "/privacy-policy" },
      ],

      contactDetails: [
        {
          type: "E-mail",
          icon: "mail.svg",
          detail: "info@mysideblog.com",
          contact: "mailto:info@mysideblog.com",
        },
        {
          type: "phone",
          icon: "phone.svg",
          detail: "+1234567890",
          contact: "tel:+1234567890",
        },
      ],

      socialMedia: [
        {
          name: "Instagram",
          to: "https://www.instagram.com/",
          image: "instagram_icon.svg",
        },
        {
          name: "Twitter",
          to: "https://twitter.com/",
          image: "twitter_icon.svg",
        },
        {
          name: "Facebook",
          to: "https://www.facebook.com/",
          image: "facebook_icon.svg",
        },
      ],

      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style>
.footerDivBorder {
  border-top: 2px solid;
  border-bottom: 2px solid;
}
</style>
