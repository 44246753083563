<template>
  <nav class="w-full appBgColorLight absolute largeScreenLayout ">
    <div class="w-full md:px-5 px-20 py-5 nav_footerLargeScreenSize ">
      <!-- First section -->
      <div class="w-full flex flex-row justify-between items-center relative">
        <div class="text-xs z-10">
          <button class="font-medium">ENG</button>
          <button class="ml-2 font-medium">FR</button>
        </div>

        <!-- logo -->
        <div class="w-full flex flex-row justify-center absolute">
          <img :src="require('@/assets/logo.png')" alt="logo" class="h-8" />
        </div>

        <div class="flex flex-row z-10">
          <!-- Dark mode button -->
          <!-- <button class="my-auto">
            <img
              :src="require('@/assets/icons/dark_mode.svg')"
              alt="dark mode"
            />
          </button> -->

          <!-- Search bar for larger screen sizez -->
          <div class="md:hidden ml-3 my-auto relative">
            <input type="text" placeholder="Search" class="inputStyle" />
            <button class="my-auto absolute right-2">
              <img :src="require('@/assets/icons/search.svg')" alt="search" />
            </button>
          </div>
        </div>
      </div>

      <!-- Second section -->
      <div class="md:hidden w-full mt-3 py-3 textColorDark navDivBorder">
        <div class="w-full flex flex-row justify-center">
          <!-- Links to other page -->
          <router-link
            v-for="item in links"
            :key="item.to"
            :to="item.to"
            class="font-medium uppercase mx-5 my-auto"
          >
            {{ item.title }}
          </router-link>

          <!-- About to link for scrolling to about section in homepage  -->
          <router-link
            v-for="scrollto in scrollToLinks"
            :key="scrollto.to"
            :to="scrollto.to"
            class="font-medium uppercase mx-5 my-auto"
            @click="navigateTo(scrollto.section)"
          >
            {{ scrollto.title }}
          </router-link>
        </div>
      </div>

      <!-- Second section mobile -->
      <div class="md-min:hidden w-full mt-3 py-3 flex flex-row">
        <div class="my-auto mr-5">
          <button class="menuButton" @click="showNavItems">
            <img :src="require('@/assets/icons/menu.svg')" alt="menu" />
          </button>
        </div>

        <div class="w-full my-auto relative">
          <input type="text" placeholder="Search" class="w-full inputStyle" />
          <button class="my-auto absolute right-2">
            <img :src="require('@/assets/icons/search.svg')" alt="search" />
          </button>
        </div>
      </div>
    </div>
  </nav>

  <!-- Sidenav overlay for smaller screens -->
  <div v-if="isSideBarOpen" class="absolute z-20">
    <section
      @click="showNavItems"
      class="h-screen w-screen bg-gray-500 fixed top-0 opacity-50"
    ></section>
  </div>

  <!-- Mobile sidebar -->
  <div
    id="mobileNav"
    class="w-4/5 h-screen pt-10 appBgColorDark absolute z-30"
    :class="isSideBarOpen == false ? '' : 'openNav'"
  >
    <div class="w-full px-5 flex flex-row justify-end">
      <button
        class="focus:outline-none p-2 text-2xl textColorLight"
        @click="showNavItems"
      >
        &#10005;
      </button>
    </div>

    <!-- Menu Items -->
    <div class="mt-5">
      <div class="textColorLight">
        <router-link
          v-for="item in links"
          :key="item.to"
          :to="item.to"
          class="font-medium uppercase block w-auto p-3 mt-1"
        >
          {{ item.title }}
        </router-link>
      </div>
    </div>

    <div class="w-full mt-5 px-5 py-5 flex flex-row">
      <a
        v-for="item in socialMedia"
        :key="item.to"
        :href="item.to"
        className="mr-4 flex flex-row items-center"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          :src="require(`@/assets/social_media_icons/${item.image}`)"
          :alt="`${item.name} icon`"
          className="my-auto"
        />
      </a>
    </div>
  </div>
</template>

<script>
import router from "../router/index";

export default {
  data() {
    return {
      isSideBarOpen: false,

      links: [
        { title: "Home", to: "/" },
        { title: "Food", to: "/" },
        { title: "Travel", to: "/" },
        { title: "Photography", to: "/" },
        { title: "Fashion & Lifestyle", to: "/" },
      ],
      scrollToLinks: [{ title: "About", to: "/", section: "about" }],

      socialMedia: [
        {
          name: "Instagram",
          to: "https://www.instagram.com/",
          image: "instagram_icon.svg",
        },
        {
          name: "Twitter",
          to: "https://twitter.com/",
          image: "twitter_icon.svg",
        },
        {
          name: "Facebook",
          to: "https://www.facebook.com/",
          image: "facebook_icon.svg",
        },
      ],
    };
  },

  methods: {
    showNavItems() {
      this.isSideBarOpen = !this.isSideBarOpen;
    },
    navigateTo(section) {
      if (this.$route.name != "Home") {
        router.push("/");
        setTimeout(() => {
          this.scrollTo(section);
        }, 1000);
      } else {
        this.scrollTo(section);
      }
    },
    scrollTo(section) {
      const element = document.getElementById(section);
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style>
.navDivBorder {
  border-top: 2px solid;
  border-bottom: 2px solid;
}
.inputStyle {
  padding-right: 35px;
}
.menuButton {
  border-radius: 100px;
  padding: 5px;
  background: var(--appColor-dark);
}

/* Animation for side navbar */
#mobileNav {
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.5s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.5s ease-in-out; /* Opera */
  -ms-transition: all 0.5s ease-in-out; /* Explorer 10 */
  transform: translateX(-100%);
}
#mobileNav.openNav {
  transform: translateX(0%);
}
</style>
