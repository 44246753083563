<template>
  <div class="w-full">
    <h3 class="text-lg textColorDark">Comments</h3>

    <div class="w-full mt-2">
      <div class="md:w-full w-1/2 py-5">
        <!-- Alert box for showing whether data was sent successfully or not -->
        <AlertBox
          :showAlertBox="showAlertBox"
          :alertMessage="alertMessage"
          :isError="isError"
          @dismiss="dismiss"
        />

        <form v-on:submit.prevent="submitForm">
          <div class="formInput">
            <label for="fname">Name*</label>
            <input name="fname" type="text" />
          </div>

          <div class="py-1 formInput">
            <label for="email">Email*</label>
            <input name="email" type="email" />
          </div>

          <div class="w-full grid grid-rows-4">
            <div class="formInput row-span-3">
              <label for="message">Your Comment*</label>
              <textarea
                name="message"
                class="h-4/5"
                v-model.trim="commentForm.comment"
                required
              />
            </div>
            <div class="formInput">
              <button class="px-10 py-2 contactButton focus:outline-none">
                <p v-if="!isLoading">Post</p>
                <p v-else>Loading...</p>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div
        class="md:w-full w-1/2"
        v-for="comment in comments"
        :key="comment.name"
      >
        <div class="w-full py-5">
          <div class="w-full flex flex-row justify-between items-center">
            <div class="flex flex-row items-center">
              <!-- Commenter profile pic -->
              <Icon
                class="mr-2"
                icon="carbon:user-avatar-filled"
                color="var(--appColor-darkAccent)"
                height="32"
              />

              <div class="flex flex-col justify-start">
                <!-- Commenter name -->
                <p class="text-sm textColorDark">
                  {{ comment.name }}
                </p>

                <!-- Comment post date -->
                <p class="text-xs textColorBlack">{{ comment.date }}</p>
              </div>
            </div>

            <div class="">
              <button class="textColorMain underline">Reply</button>
            </div>
          </div>

          <div class="w-full py-2 px-5">
            <p class="font-quattrocento">{{ comment.comment }}</p>
          </div>

          <div class="flex flex-row">
            <div class="flex flex-row items-center">
              <Icon
                icon="bx:bxs-like"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              <p class="ml-2 textColorDarkAccent font-quattrocento">
                {{ comment.likes }}
              </p>
            </div>

            <div class="ml-4 flex flex-row items-center">
              <Icon
                icon="bx:bxs-dislike"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              <p class="ml-2 textColorDarkAccent font-quattrocento">
                {{ comment.dislikes }}
              </p>
            </div>
          </div>
        </div>

        <!-- Replies -->
        <div
          class="w-full md:ml-5 ml-10 py-4"
          v-for="reply in comment.replies"
          :key="reply.name"
        >
          <div class="w-full flex flex-row justify-between items-center">
            <div class="flex flex-row items-center">
              <!-- Commenter profile pic -->
              <Icon
                class="mr-2"
                icon="carbon:user-avatar-filled"
                color="var(--appColor-darkAccent)"
                height="32"
              />

              <div class="flex flex-col justify-start">
                <!-- Commenter name -->
                <p class="text-sm textColorDark">
                  {{ reply.name }}
                </p>

                <!-- Comment post date -->
                <p class="text-xs textColorBlack">{{ reply.date }}</p>
              </div>
            </div>
          </div>

          <div class="w-full py-2 px-5">
            <p class="font-quattrocento">{{ reply.comment }}</p>
          </div>

          <div class="flex flex-row">
            <div class="flex flex-row items-center">
              <Icon
                icon="bx:bxs-like"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              <p class="ml-2 textColorDarkAccent font-quattrocento">
                {{ reply.likes }}
              </p>
            </div>

            <div class="ml-4 flex flex-row items-center">
              <Icon
                icon="bx:bxs-dislike"
                color="var(--appColor-darkAccent)"
                height="20"
              />
              <p class="ml-2 textColorDarkAccent font-quattrocento">
                {{ reply.dislikes }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import axios from "../../../plugins/axios";
import AlertBox from "../../../components/AlertBox.vue"

export default {
  components: {
    Icon,
    AlertBox,
  },

  props: {
    comments: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isLoading: false,
      isError: false,
      showAlertBox: false,
      alertMessage: "",

      commentForm: {
        name: "",
        email: "",
        subject: "Comment",
        comment: "",
      },
    };
  },

  methods: {
    submitForm() {
      this.isLoading = true;
      //change your api base url at the VUE_APP_API_URL variable in the .env file
      axios
        .post("/api/comments", this.commentForm) //post to your API endpoint i.e: '/api/comments'  Change it to your API's endpoint where you expect to receive the data
        .then(() => {
          this.isError = false;
          this.alertMessage = "Your comment has been posted successfully";

          //clear input fields if the data was sent to the API successfully
          this.commentForm.name = "";
          this.commentForm.email = "";
          this.commentForm.comment = "";
        })
        .catch(() => {
          //set isError to true & add error message to alertMessage variable
          this.isError = true;
          this.alertMessage = "Failed to post your comment, please try again";
        })
        .finally(() => {
          this.showAlertBox = true;
          this.isLoading = false;

          //clear alertMessage after 10 seconds
          setTimeout(() => {
            this.showAlertBox = false;
            this.alertMessage = "";
          }, 10000);
        });
    },

    // function for dismissing alert box
    dismiss() {
      this.showAlertBox = false;
      this.alertMessage = "";
    },
  },
};
</script>

<style></style>
