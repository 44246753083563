import { createRouter, createWebHistory } from "vue-router";
import { Home, SingleBlogPost, BecomeBlogger, PrivacyPolicy, PageNotFound } from "../views/index";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/blog-post",
    name: "SingleBlogPost",
    component: SingleBlogPost,
  },
  {
    path: "/become-a-blogger",
    name: "BecomeBlogger",
    component: BecomeBlogger,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  { path: "/:catchAll(.*)", name: "PageNotFound", component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
