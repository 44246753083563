<template>
  <div
    class="w-full flex flex-col"
    v-for="detail in authorDetails"
    :key="detail.authorName"
  >
    <div className="py-3 flex flex-row items-center">
      <div class="authorProfilepic">
        <img
          class="maintainRatio"
          :src="require(`@/assets/images/${detail.authorPic}`)"
          alt="profile picture"
        />
      </div>

      <div class="ml-2 flex flex-col items-center">
        <p className="text-lg font-varelaRound textColorDarkAccent">
          About {{ detail.authorName }}
        </p>

        <!-- Author social media links  -->
        <div class="w-full mt-2 flex flex-row">
          <a
            v-for="link in detail.socialMedia"
            :key="link.to"
            :href="link.to"
            className="mr-4 flex flex-row items-center"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              :icon="link.icon"
              color="var(--appColor-darkAccent)"
              height="24"
            />
          </a>
        </div>
      </div>
    </div>

    <!-- Author bio -->
    <div class="w-full">
      <p class="font-quattrocento textColorDark">
        {{ detail.authorBio }}
      </p>
    </div>

    <!-- Author contact details -->
    <div
      class="w-full mt-4 flex flex-row items-center"
      :class="[detail.authorEmail != null ? '' : 'hidden']"
    >
      <Icon icon="fontisto:email" color="var(--appColor-dark)" height="20" />
      <a
        class="ml-2 textColorDark lowercase"
        :href="`mailto:${detail.authorEmail}`"
      >
        {{ detail.authorEmail }}
      </a>
    </div>

    <div
      class="w-full mt-4 flex flex-row items-center"
      :class="[detail.authorTel != null ? '' : 'hidden']"
    >
      <Icon icon="akar-icons:phone" color="var(--appColor-dark)" height="20" />
      <a class="ml-2 textColorDark lowercase" :href="`tel:${detail.authorTel}`">
        {{ detail.authorTel }}
      </a>
    </div>

    <!-- More from the author button  -->
    <div class="mt-10">
      <button class="py-2 textColorMain">
        More from {{ detail.authorName }}
      </button>
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";

export default {
  components: {
    Icon,
  },

  props: {
    authorDetails: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
.authorProfilepic {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  overflow: hidden;
}
</style>
