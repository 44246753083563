<template>
  <div class="w-full flex md:flex-col flex-row md-min:justify-between">
    <!-- Previous blog selector -->
    <div
      class="md:w-full w-1/2 md:mb-4 flex flex-row items-center cursor-pointer"
      v-for="item in previousArticle"
      :key="item.index"
      @click="goToPreviousArticle"
    >
      <Icon
        class="mr-2"
        icon="akar-icons:circle-chevron-left-fill"
        color="var(--appColor-dark)"
        height="40"
      />
      <div class="flex flex-row items-center">
        <div class="w-20 h-20 mr-2">
          <img
            class="maintainRatio"
            :src="require(`@/assets/images/${item.image}`)"
            alt=""
          />
        </div>
        <p class="font-varelaRound textColorDark capitalize">
          {{ item.title }}
        </p>
      </div>
    </div>

    <!-- Next article selector -->
    <div
      class="md:w-full w-1/2 md-min:ml-10 md:mt-4 flex flex-row justify-end items-center cursor-pointer"
      v-for="itemNext in nextArticle"
      :key="itemNext.index"
      @click="goToNextArticle"
    >
      <div class="flex flex-row justify-end items-center">
        <p class="font-varelaRound textColorDark capitalize">
          {{ itemNext.title }}
        </p>
        <div class="w-20 h-20 ml-2">
          <img
            class="maintainRatio"
            :src="require(`@/assets/images/${itemNext.image}`)"
            alt=""
          />
        </div>
      </div>
      <Icon
        class="ml-2"
        icon="akar-icons:circle-chevron-right-fill"
        color="var(--appColor-dark)"
        height="40"
      />
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      previousArticle: [
        {
          index: 1,
          image: "lily-banse--YHSwy6uqvk-unsplash.jpg",
          title: "My Amazing Food Experience in Lamu",
        },
      ],
      nextArticle: [
        {
          index: 1,
          image: "cristofer-maximilian-uQDRDqpYJHI-unsplash.jpg",
          title: "Chasing Sunsets & amazing tropical vibes",
        },
      ],
    };
  },

  methods: {
    goToPreviousArticle() {
      //function to go to privious article
    },

    goToNextArticle() {
      //function to go to next article
    },
  },
};
</script>

<style>
/* Use ellipsis for blog titles i.e: continuation dots (...) */
/* .nextBlogTitleOverflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  overflow: hidden;
}

@media (max-width: 410px) {
  .nextBlogTitleOverflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 25%;
    overflow: hidden;
  }
} */
</style>
