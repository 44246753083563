<template>
  <section class="w-full md:px-5 px-20 py-32">
    <h1 class="text-2xl font-medium capitalize textColorDark">
      Become a Blogger
    </h1>

    <div class="w-full py-10">
      <!-- Alert box for showing whether data was sent successfully or not -->
      <AlertBox
        :showAlertBox="showAlertBox"
        :alertMessage="alertMessage"
        :isError="isError"
        @dismiss="dismiss"
      ></AlertBox>

      <form v-on:submit.prevent="submitForm">
        <div class="w-full flex flex-row justify-between md:flex-col">
          <!-- First column -->
          <div class="md-min:w-1/2 w-full md-min:mr-10">
            <div class="formInput">
              <label for="fname">Name*</label>
              <input
                name="fname"
                type="text"
                v-model.trim="contactForm.name"
                required
              />
            </div>
            <div class="formInput">
              <label for="email">Email*</label>
              <input
                name="email"
                type="email"
                v-model.trim="contactForm.email"
                required
              />
            </div>
            <div class="formInput">
              <label for="phone">Mobile Number*</label>
              <input
                name="phone"
                type="tel"
                v-model.trim="contactForm.phoneno"
                required
              />
            </div>
          </div>

          <!-- Second column -->
          <div class="md-min:w-1/2 w-full grid grid-rows-4">
            <div class="formInput row-span-3">
              <label for="message">Message*</label>
              <textarea
                name="message"
                class="h-4/5"
                v-model.trim="contactForm.message"
                required
              />
            </div>
            <div class="formInput">
              <button class="px-10 py-2 contactButton focus:outline-none">
                <p v-if="!isLoading">Send Request</p>
                <p v-else>Loading...</p>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div class="w-full mt-5 flex flex-row justify-center">
        <p class="text-sm textColorDarkAccent">* required</p>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "../../plugins/axios";
import AlertBox from "../../components/AlertBox";

export default {
  components: {
    AlertBox,
  },

  data() {
    return {
      isLoading: false,
      isError: false,
      showAlertBox: false,
      alertMessage: "",

      contactForm: {
        name: "",
        email: "",
        phoneno: "",
        subject: "Become a blogger request",
        message: "",
      },
    };
  },

  methods: {
    submitForm() {
      this.isLoading = true;
      //change your api base url at the VUE_APP_API_URL variable in the .env file
      axios
        .post("/api/notification", this.contactForm) //post to your API endpoint i.e: '/api/notification'  Change it to your API's endpoint where you expect to receive the data
        .then(() => {
          this.isError = false;
          this.alertMessage = "Your message has been sent successfully";

          //clear input fields if the data was sent to the API successfully
          this.contactForm.name = "";
          this.contactForm.email = "";
          this.contactForm.phoneno = "";
          this.contactForm.message = "";
        })
        .catch(() => {
          //set isError to true & add error message to alertMessage variable
          this.isError = true;
          this.alertMessage = "Failed to send your message, please try again";
        })
        .finally(() => {
          this.showAlertBox = true;
          this.isLoading = false;

          //clear alertMessage after 10 seconds
          setTimeout(() => {
            this.showAlertBox = false;
            this.alertMessage = "";
          }, 10000);
        });
    },

    // function for dismissing alert box
    dismiss() {
      this.showAlertBox = false;
      this.alertMessage = "";
    },
  },
};
</script>

<style></style>
