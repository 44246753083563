<template>
  <div class="w-full h-screen flex flex-col justify-center items-center">
    <div class="text-center">
      <p class="capitalize text-3xl font-bold py-5 textColorDark">Page not found</p>
      <p class="capitalize text-xl textColorDark">
        <span class="textColorMain">
          <router-link to="/">Click here </router-link>
        </span>
        to go back to the home page
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
