<template>
  <section class="w-full">
    <LandingSection />
    <AboutUsSection />
    <NewsletterSection />
    <LeaveMessageSection />
  </section>
</template>

<script>
import LandingSection from "./Sections/LandingSection.vue";
import AboutUsSection from "./Sections/AboutUsSection.vue";
import NewsletterSection from "./Sections/NewsletterSection.vue";
import LeaveMessageSection from "./Sections/LeaveMessageSection.vue";

export default {
  name: "Home",
  components: {
    LandingSection,
    AboutUsSection,
    NewsletterSection,
    LeaveMessageSection,
  },
};
</script>